export const FirebaseConfig = {
	"projectId": "aflixapro-c7fe9",
	"appId": "1:955944064544:web:9e552d33a41be11f64ce45",
	"databaseURL": "https://aflixapro-c7fe9-default-rtdb.firebaseio.com",
	"storageBucket": "aflixapro-c7fe9.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAogSw7YJLnoYKuinXNAP5vwPn9CLG7v14",
	"authDomain": "aflixapro-c7fe9.firebaseapp.com",
	"messagingSenderId": "955944064544",
	"measurementId": "G-D2Q7R488HC"
};